<template>
  
 
	<section class="dark:bg-gray-800 dark:text-gray-100">
		<div class="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
			<div>
				<h2 class="text-3xl font-bold tracking-tight text-center sm:text-5xl dark:text-gray-50">{{eventData.name}}</h2>
				<p class="max-w-3xl mx-auto mt-4 text-xl text-center dark:text-gray-400">A Clinic Event</p>
			</div>
			<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
				<div>
					<h3 class="text-2xl font-bold tracking-tight sm:text-3xl dark:text-gray-50">Mastering the Drop</h3>
					<p class="mt-3 text-lg dark:text-gray-400">Ne dicta praesent ocurreret has, diam theophrastus at pro. Eos etiam regione ut, persius eripuit quo id. Sit te euismod tacimates.</p>
					<div class="mt-12 space-y-12">
						<div class="flex">
							<div class="flex-shrink-0">
								<div class="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 h-7">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div class="ml-4">
								<h4 class="text-lg font-medium leading-6 dark:text-gray-50">Per ei quaeque sensibus</h4>
								<p class="mt-2 dark:text-gray-400">Ex usu illum iudico molestie. Pro ne agam facete mediocritatem, ridens labore facete mea ei. Pro id apeirian dignissim.</p>
							</div>
						</div>
						<div class="flex">
							<div class="flex-shrink-0">
								<div class="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 h-7">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div class="ml-4">
								<h4 class="text-lg font-medium leading-6 dark:text-gray-50">Cu imperdiet posidonium sed</h4>
								<p class="mt-2 dark:text-gray-400">Amet utinam aliquando ut mea, malis admodum ocurreret nec et, elit tibique cu nec. Nec ex maluisset inciderint, ex quis.</p>
							</div>
						</div>
						<div class="flex">
							<div class="flex-shrink-0">
								<div class="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 h-7">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
									</svg>
								</div>
							</div>
							<div class="ml-4">
								<h4 class="text-lg font-medium leading-6 dark:text-gray-50">Nulla omittam sadipscing mel ne</h4>
								<p class="mt-2 dark:text-gray-400">At sed possim oporteat probatus, justo graece ne nec, minim commodo legimus ut vix. Ut eos iudico quando soleat, nam modus.</p>
							</div>
						</div>
					</div>
				</div>
				<div aria-hidden="true" class="mt-10 lg:mt-0">
					<img src="https://source.unsplash.com/random/360x480" alt="" class="mx-auto rounded-lg shadow-lg dark:bg-gray-500">
				</div>
			</div>
			<!-- <div>
				<div class="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
					<div class="lg:col-start-2">
						<h3 class="text-2xl font-bold tracking-tight sm:text-3xl dark:text-gray-50">Eam nibh gloriatur ex</h3>
						<p class="mt-3 text-lg dark:text-gray-400">Per odio fabellas consulatu cu. Utroque detracto mel ea, quo te latine theophrastus. Ea his tale nibh dissentias, mei exerci tamquam euripidis cu.</p>
						<div class="mt-12 space-y-12">
							<div class="flex">
								<div class="flex-shrink-0">
									<div class="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 h-7">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
										</svg>
									</div>
								</div>
								<div class="ml-4">
									<h4 class="text-lg font-medium leading-6 dark:text-gray-50">Cibo augue offendit has ad</h4>
									<p class="mt-2 dark:text-gray-400">An per velit appellantur, ut utinam minimum nominavi sit, odio nostro habemus ne nec. Ne sonet regione contentiones est.</p>
								</div>
							</div>
							<div class="flex">
								<div class="flex-shrink-0">
									<div class="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 h-7">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
										</svg>
									</div>
								</div>
								<div class="ml-4">
									<h4 class="text-lg font-medium leading-6 dark:text-gray-50">At eum ferri luptatum lobortis</h4>
									<p class="mt-2 dark:text-gray-400">Te per quidam maiorum ocurreret, etiam delicatissimi usu ad. Ne has quod periculis. Te sit primis iisque efficiantur.</p>
								</div>
							</div>
							<div class="flex">
								<div class="flex-shrink-0">
									<div class="flex items-center justify-center w-12 h-12 rounded-md dark:bg-violet-400 dark:text-gray-900">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-7 h-7">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
										</svg>
									</div>
								</div>
								<div class="ml-4">
									<h4 class="text-lg font-medium leading-6 dark:text-gray-50">Dicunt verterem evertitur eu sea</h4>
									<p class="mt-2 dark:text-gray-400">Audire principes rationibus eam an, autem nominavi luptatum per te. Sumo fabulas vim eu, sonet saperet eleifend ut vix.</p>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1">
						<img src="https://source.unsplash.com/random/361x481" alt="" class="mx-auto rounded-lg shadow-lg dark:bg-gray-500">
					</div>
				</div>
			</div> -->
		</div>
	</section>

</template>

<script>
import { onMounted, ref } from "@vue/runtime-core"
import { useRoute } from "vue-router"
import { doc, getDoc } from '@firebase/firestore'
import { db } from '@/firebase/config'

export default {
    setup(){
        
        const route = useRoute()
        const eventData = ref({})
        const paramId = ref()
        onMounted(()=>{
            paramId.value = route.params.eventid
            profileGetter(paramId.value)
        })

        const profileGetter = async (docid)=> {   
            const docRef = doc(db, "events", docid);            
            let docSnap = await getDoc(docRef)
            if (docSnap.exists()) {              
                eventData.value = ({ ...docSnap.data()})
                  
            } else {
                // doc.data() will be undefined in this case
                console.log("No such event!");
            }
        }

        return { eventData}
    }
    
}
</script>

<style>

</style>