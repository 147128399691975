<template>
  <!-- <div class=" grid grid-cols-1 h-max-full">        -->
        <!-- <div class=" bg-black col-span-full row-span-full md:col-start-2 md:col-end-5  md:row-start-1 md:row-end-4 "> -->
        <!-- <div>
            <img src="../assets/carbonLayout.jpg" aria-hidden="true" class=" " > 
        </div>
        <div class=" col-start-3 row-start-6">
        <div>
          <img src="../assets/logoWL.png" alt="">
        </div> -->

       
	<div class="min-h-screen dark:bg-gray-800 dark:text-gray-100">
		<div class="p-6 space-y-8">
			
			<main>
				<div class="container mx-auto space-y-16">
					<section class="grid gap-6 text-center lg:grid-cols-2 xl:grid-cols-5">
						<div class="w-full p-6 rounded-md sm:p-16 xl:col-span-2 dark:bg-gray-900">
							<span class="block mb-2 dark:text-violet-400">Proud user of</span>
							<h1 class="text-5xl font-extrabold dark:text-gray-50">CRBN Paddles</h1>
							<p class="my-8">
								<span class="font-medium dark:text-gray-50"> unparalleled power and control on the court.</span>
							</p>
							<!-- <form novalidate="" action="" class="self-stretch space-y-3 ng-untouched ng-pristine ng-valid">
								<div>
									<label for="name" class="text-sm sr-only">Your name</label>
									<input id="name" type="text" placeholder="Your name" class="w-full rounded-md focus:ring focus:ring-violet-400 dark:border-gray-700">
								</div>
								<div>
									<label for="lastname" class="text-sm sr-only">Email address</label>
									<input id="lastname" type="text" placeholder="Email address" class="w-full rounded-md focus:ring focus:ring-violet-400 dark:border-gray-700">
								</div>
								<button class="w-full py-2 font-semibold rounded dark:bg-violet-400 dark:text-gray-900">Join the waitlist</button>
							</form> -->
						</div>
						<img src="../assets/carbonLayout.jpg" alt="" class="object-cover w-full h-full rounded-md xl:col-span-3 dark:bg-gray-500">
					</section>
				
					<section class="grid gap-6 lg:grid-cols-3">
						<img src="../assets/paddlewrap2.jpg" alt="" class="object-cover  rounded-md max-h-96 dark:bg-gray-500">
            
						<div class="flex flex-col items-center w-full p-6 space-y-8 rounded-md lg:h-full lg:p-8 dark:bg-gray-900">
							<img src="../assets/profile1.jpg" alt="" class="object-cover w-20 h-20 rounded-full dark:bg-gray-500">
							<blockquote class="max-w-lg text-lg italic font-medium text-center">Personalize your Paddles and Rackets</blockquote>
							<div class="text-center dark:text-gray-400">
								<p> 50% off on purchase of 4 or more with </p>
								<a rel="noopener noreferrer" target="_blank" href="http://mypadl.com/Walter" class="font-bold">Mypadl Referral Link</a>
							</div>              
							<div class="flex space-x-2">
								<button type="button" aria-label="Page 1" class="w-2 h-2 rounded-full dark:bg-gray-50"></button>
								<button type="button" aria-label="Page 2" class="w-2 h-2 rounded-full dark:bg-gray-600"></button>
								<button type="button" aria-label="Page 3" class="w-2 h-2 rounded-full dark:bg-gray-600"></button>
								<button type="button" aria-label="Page 4" class="w-2 h-2 rounded-full dark:bg-gray-600"></button>
							</div>              
						</div>
            <img src="../assets/paddlewrap1.png" alt="" class="object-cover  rounded-md max-h-96 dark:bg-gray-500">

						<!-- <div class="p-8 space-y-8 rounded-md lg:col-span-full lg:py-12 dark:bg-gray-900">
							<h2 class="text-5xl font-bold dark:text-gray-50">Create with us</h2>
							<p class="dark:text-gray-400">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt facilis quod accusantium beatae cum nam adipisci reiciendis omnis possimus error quo animi voluptas magni, at incidunt. Nulla ex at ullam corporis quidem adipisci vitae, perferendis dolorem libero minus atque tenetur enim pariatur cupiditate commodi in beatae, ipsa eligendi? Quis, saepe.</p>
						</div> -->
					</section>	
				</div>
			</main>
			<footer>
				<div class="container flex justify-between p-6 mx-auto lg:p-8 dark:bg-gray-900">
					<a rel="noopener noreferrer" href="#" class="font-bold">Walter Lau Pickleball</a>
					<div class="flex space-x-2">
						<!-- <a aria-label="Email" rel="noopener noreferrer" href="#" class="flex items-center justify-center dark:text-gray-400">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
								<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
								<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
							</svg>
						</a>
						<a aria-label="Twitter" rel="noopener noreferrer" href="#" class="flex items-center justify-center dark:text-gray-400">
							<svg fill="currentColor" class="w-5 h-5" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 310 310" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_834_"> <path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064 c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996 V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545 C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703 c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"></path> </g> </g></svg>
						</a>					 -->
					</div>					
				</div>
			</footer>
		</div>
	</div>

</template>

<script>

</script>

<style>



</style>